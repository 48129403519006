import { $, addClass, append, appendTo, attr, children, click, closest, css, data, each, filter, find, hasClass, hide, html, is, next, nextAll, off, on, parent, parents, prev, prevAll, remove, removeAttr, removeClass, show, siblings, text, toggleClass, trigger, } from 'dom7';
var Methods = {
    addClass: addClass,
    append: append,
    appendTo: appendTo,
    attr: attr,
    children: children,
    click: click,
    closest: closest,
    css: css,
    data: data,
    each: each,
    filter: filter,
    find: find,
    hasClass: hasClass,
    hide: hide,
    html: html,
    is: is,
    next: next,
    nextAll: nextAll,
    off: off,
    on: on,
    parent: parent,
    parents: parents,
    prev: prev,
    prevAll: prevAll,
    remove: remove,
    removeAttr: removeAttr,
    removeClass: removeClass,
    show: show,
    siblings: siblings,
    text: text,
    toggleClass: toggleClass,
    trigger: trigger,
};
Object.keys(Methods).forEach(function (methodName) {
    Object.defineProperty($.fn, methodName, {
        value: Methods[methodName],
        writable: true
    });
});
export default $;
