var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { hidePreloader, showPreloader } from "./preloader";
import { addGlobalLiveEventListener, fixInjectedInlineScripts, onTransitionEnd } from '../utils';
import ajax from "./ajax";
import $ from '../vendor/dom7';
import { StatusClassNames } from "../types/StatusClassNames";
import EventBus from "../events/EventBus";
var unrouted = ['active_user', 'review'];
var modalStack = new Set();
export default function initModal() {
    document.addEventListener('DOMContentLoaded', function () {
        // ссылки, вызывающие модальные окна
        addGlobalLiveEventListener('click', '.win', function (e) {
            var button = this;
            if (button.dataset.modalId) {
                var dialog = document.getElementById("modal-".concat(button.dataset.modalId));
                if (dialog) {
                    showDialogModal(dialog);
                    return;
                }
            }
            var act = button.dataset.act;
            e.preventDefault();
            showPreloader();
            ajax("/ajax/win/".concat(act), __assign(__assign({}, (button.dataset.id ? { id: button.dataset.id } : {})), { type: button.dataset.type }))
                .then(function (response) {
                var contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("/json") !== -1) {
                    return response.json();
                }
                return response.text();
            })
                .then(function (data) {
                if (typeof data === 'string') {
                    showModal(data);
                }
                else if (data.redirect) {
                    document.location.href = data.redirect;
                    // @ts-ignore
                    document.location.reload(true);
                }
                else {
                    var assets = data.assets, callback_1 = data.callback, html_1 = data.html, appData_1 = __rest(data, ["assets", "callback", "html"]);
                    initAssets(data)
                        .then(function (element) {
                        if (html_1) {
                            element = showModal(element !== null && element !== void 0 ? element : html_1);
                        }
                        if (callback_1) {
                            if (typeof global[callback_1] === 'function') {
                                return global[callback_1](appData_1, element);
                            }
                        }
                    });
                }
            });
            function showModal(html) {
                var id = showModalFromHtml(html);
                if (id) {
                    button.dataset.modalId = id;
                }
                hidePreloader();
                return document.getElementById(id);
            }
        });
        // отправка форм аяксом в модальных окнах
        addGlobalLiveEventListener('submit', 'form', function (e) {
            var _a, _b;
            var form = this;
            var act = (_b = (_a = (e.submitter ? e.submitter : form.querySelector('button[type="submit"]'))) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b.act;
            var url = "/ajax/win_act/".concat(act).concat(unrouted.includes(act) ? '.php' : '');
            if (!act || !e.submitter.classList.contains('ajax_submit')) {
                return;
            }
            e.preventDefault();
            showPreloader();
            var closePreloader = true;
            ajax(url, form, { submitter: e.submitter })
                .then(function (response) { return response.json(); })
                .then(function (json) {
                if (json && json.ok) {
                    EventBus.emit('modal:form-success', { action: act, form: form, response: json });
                    if (json.html) {
                        showModalFromHtml(json.html);
                    }
                    else if (json.win) {
                        var existingModal = document.getElementById(json.win);
                        if (existingModal) {
                            showDialogModal(existingModal);
                        }
                        else {
                            $('<a class="win" data-act="' + json.win + '"></a>').appendTo('.window').click().remove();
                        }
                    }
                    else {
                        var modal = form.closest('.modal');
                        var redirect = modal === null || modal === void 0 ? void 0 : modal.dataset.redirect;
                        closePreloader = false;
                        if (modal && (modal === null || modal === void 0 ? void 0 : modal.dataset.noRedirect) !== undefined) {
                            return;
                        }
                        if (!redirect) {
                            //@ts-ignore
                            document.location.reload(true);
                        }
                        else {
                            document.location.href = redirect;
                            // @ts-ignore
                            document.location.reload(true);
                        }
                        return;
                    }
                }
                else if (json && typeof json.error === 'object') {
                    highlightFormErrors(form, json.error);
                    EventBus.emit('modal:form-error', { action: act, errors: json.error, form: form });
                }
            })
                .finally(function () { return closePreloader && hidePreloader(); });
        });
        // ajax submittable forms
        $(document.body).on('click', '.ajax_submit', function () {
            var $form = $(this).parents('form');
            if (this.type !== 'submit') {
                var $submit = $form.find('[type=submit]');
                if (!$submit.length) {
                    var $input = $('<input type="submit">');
                    $input.hide();
                    $submit = $input.appendTo($form);
                }
                $submit.click();
            }
        });
        $('.win[data-open="1"]').click();
        var autoOpenModal = document.querySelector('dialog.modal[data-auto-open]');
        if (autoOpenModal) {
            showDialogModal(autoOpenModal);
        }
        // handle hash navigation
        checkHashDeeplink(document.location.hash.replace(/^#/, ''));
        EventBus.on('modal:close', function (modal) {
            if (modal.dataset.redirect) {
                showPreloader();
                document.location.href = modal.dataset.redirect;
                // @ts-ignore
                document.location.reload(true);
            }
        });
    });
}
export function highlightFormErrors(form, errors) {
    if (errors === void 0) { errors = {}; }
    resetFormErrors(form);
    for (var field in errors) {
        var element = form.querySelector("input[name=\"".concat(field, "\"],textarea[name=\"").concat(field, "\"],select[name=\"").concat(field, "\"]"));
        var group = element.closest('.group, .form-group');
        var caption = group.querySelector('.form-caption--error');
        if (caption) {
            group.classList.add('has-error');
            group.querySelector('.form-caption--error').innerText = errors[field];
        }
    }
}
export function resetFormErrors(form) {
    form.querySelectorAll('.has-error').forEach(function (el) { return el.classList.remove('has-error'); });
}
export function closeExistingModal(destroy, exclude) {
    var e_1, _a;
    if (destroy === void 0) { destroy = false; }
    if (exclude === void 0) { exclude = null; }
    try {
        for (var _b = __values(document.querySelectorAll('.window dialog.modal[open]')), _c = _b.next(); !_c.done; _c = _b.next()) {
            var openModal = _c.value;
            if (exclude === openModal) {
                continue;
            }
            closeDialogModal(openModal, destroy);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
export function showModalFromHtml(modal) {
    var modalRoot = document.querySelector('.window');
    if (!modalRoot) {
        return;
    }
    if (typeof modal === 'string') {
        var wrapper = document.createElement('div');
        wrapper.innerHTML = modal;
        modal = wrapper.firstElementChild;
    }
    var existingSameModal = document.getElementById(modal.id);
    if (existingSameModal) {
        closeDialogModal(existingSameModal);
    }
    modalRoot.append(modal);
    fixInjectedInlineScripts(modalRoot);
    showDialogModal(modal);
    return modal.id;
}
export function getModalAppRootElement() {
    var e_2, _a;
    var modalsWrap = document.querySelector('.window');
    var appRoot = document.createElement('div');
    try {
        for (var _b = __values(document.querySelectorAll('.window dialog.modal:not([open])')), _c = _b.next(); !_c.done; _c = _b.next()) {
            var openModal = _c.value;
            destroyModal(openModal);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_2) throw e_2.error; }
    }
    closeExistingModal(true);
    modalsWrap.append(appRoot);
    return appRoot;
}
export function getTopmostModal() {
    return __spreadArray([], __read(modalStack), false).pop();
}
export function closeDialogModal(modal, destroy) {
    if (destroy === void 0) { destroy = false; }
    modalStack.delete(modal);
    modal.classList.remove(StatusClassNames.open);
    modal.classList.add(StatusClassNames.closing);
    modal.querySelectorAll('button[data-close]').forEach(function (el) { return el.removeEventListener('click', onCloseButtonClick); });
    EventBus.emit('modal:close', modal);
    onTransitionEnd(modal, function () {
        modal.close();
        modal.ariaHidden = 'true';
        modal.classList.add('hidden');
        modal.classList.remove(StatusClassNames.closing);
        if (!document.querySelector('dialog.modal[open]')) {
            document.body.classList.remove(StatusClassNames.noscroll);
        }
        if (destroy) {
            destroyModal(modal);
        }
        else {
            var form = modal.querySelector('form');
            if (form) {
                resetFormErrors(form);
            }
        }
    }, 'opacity');
}
export function showDialogModal(modal) {
    modalStack.add(modal);
    if (modal.dataset.final !== undefined) {
        closeExistingModal(true, modal);
    }
    EventBus.emit('modal:open', modal);
    document.body.classList.add(StatusClassNames.noscroll);
    modal.ariaHidden = 'false';
    modal.showModal();
    handleEscapeCloseTransition(modal);
    closeModalOnBackdropClick(modal);
    modal.classList.remove('hidden');
    modal.querySelectorAll('button[data-close]').forEach(function (el) { return el.addEventListener('click', onCloseButtonClick); });
    setTimeout(function () {
        modal.classList.add(StatusClassNames.open);
        hidePreloader();
    }, 20);
}
function onCloseButtonClick(event) {
    var modal = event.target.closest('dialog.modal[open]');
    if (modal) {
        closeDialogModal(modal);
    }
}
function destroyModal(modal) {
    var app = modal.closest('[data-v-app]');
    if (app && app['__vue_app__']) {
        app['__vue_app__'].unmount();
        app.remove();
    }
    else {
        modal.remove();
    }
}
function closeModalOnBackdropClick(modal) {
    var backdropClick = false;
    var onpointerdown = function (event) { return backdropClick = event.target === modal; };
    var onpointerup = function (event) {
        if (!backdropClick || event.target !== modal) {
            backdropClick = false;
            return;
        }
        modal.removeEventListener('pointerdown', onpointerdown);
        modal.removeEventListener('pointerup', onpointerup);
        closeDialogModal(modal);
    };
    modal.addEventListener('pointerdown', onpointerdown);
    modal.addEventListener('pointerup', onpointerup);
}
var loading = 0;
function initAssets(data) {
    if (!data.assets) {
        return new Promise(function (resolve) { return resolve(null); });
    }
    var existingAssets = __spreadArray(__spreadArray([], __read(Array.from(document.scripts).map(function (el) { return el.src; })), false), __read(Array.from(document.querySelectorAll('link[rel="stylesheet"]')).map(function (el) { return el.href; })), false);
    var virtual = document.createElement('div');
    virtual.innerHTML = data.html + data.assets;
    return new Promise(function (resolve) {
        var e_3, _a, e_4, _b, e_5, _c;
        var resolveCallback = function () {
            resolve(virtual.firstElementChild);
        };
        try {
            for (var _d = __values(virtual.querySelectorAll('script[src]')), _e = _d.next(); !_e.done; _e = _d.next()) {
                var element = _e.value;
                if (!existingAssets.includes(element.src)) {
                    enqueueAsset(element, resolveCallback);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_3) throw e_3.error; }
        }
        try {
            for (var _f = __values(virtual.querySelectorAll('link')), _g = _f.next(); !_g.done; _g = _f.next()) {
                var element = _g.value;
                if (!existingAssets.includes(element.href)) {
                    enqueueAsset(element, resolveCallback);
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_4) throw e_4.error; }
        }
        if (data.html) {
            try {
                for (var _h = __values(virtual.querySelectorAll('script,style')), _j = _h.next(); !_j.done; _j = _h.next()) {
                    var element = _j.value;
                    virtual.firstElementChild.append(element);
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                }
                finally { if (e_5) throw e_5.error; }
            }
        }
        if (!loading) {
            resolveCallback();
        }
    });
}
function enqueueAsset(element, resolve) {
    loading++;
    var el = document.createElement(element.tagName);
    document.body.append(el);
    el.onload = function () { return !--loading && resolve(); };
    if ('src' in el && 'src' in element) {
        if (element.type) {
            el.type = element.type;
        }
        else if ('noModule' in el) {
            el.noModule = element.attributes['nomodule'] !== undefined;
            loading--;
        }
        el.src = element.src;
    }
    else if ('href' in el && 'href' in element) {
        el.rel = element.rel;
        el.href = element.href;
    }
    element.remove();
}
function handleEscapeCloseTransition(modal) {
    modal.addEventListener('keydown', function (e) {
        if (e.code === 'Escape') {
            // Этот код нужен т.к. иначе браузер закроет диалог мгновенно и события keyup не произойдёт
            e.preventDefault();
        }
    });
    modal.addEventListener('keyup', function (e) {
        if (e.code === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            closeDialogModal(modal);
        }
    });
}
function checkHashDeeplink(hash) {
    if (!hash) {
        return;
    }
    var modal = document.querySelector(".win[data-act=\"".concat(hash, "\"]"));
    if (!modal) {
        return;
    }
    modal.click();
}
