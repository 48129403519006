import { getTopmostModal } from "./modal";
var globalPreloader;
var currentPreloader;
var preloaderActive = false;
var timeout;
export var ClassNames;
(function (ClassNames) {
    ClassNames["main"] = "preloader";
    ClassNames["active"] = "preloader--active";
    ClassNames["enabled"] = "preloader--enabled";
    ClassNames["inline"] = "preloader--inline";
})(ClassNames || (ClassNames = {}));
export function makePreloader(el) {
    if (isPreloaderActive(el)) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = false;
        }
        return {
            close: hidePreloader
        };
    }
    doShow(el);
    return {
        close: function () { return doHide(el); },
    };
}
export function hidePreloader(delay, el) {
    var preloader = (el ? el : currentPreloader);
    if (!preloader) {
        return;
    }
    if (delay) {
        timeout = setTimeout(hidePreloader.bind(this, false, preloader), 150);
        return;
    }
    doHide(preloader);
}
function doHide(el) {
    el.classList.remove(ClassNames.active);
    preloaderActive = false;
    el.addEventListener('transitionend', disable.bind(el), { once: true });
}
function isPreloaderActive(el) {
    if (!preloaderActive || !currentPreloader) {
        return false;
    }
    if (!currentPreloader.offsetHeight) {
        doHide(currentPreloader);
        if (timeout) {
            clearTimeout(timeout);
        }
        return false;
    }
    return !el || el === currentPreloader;
}
export function showPreloader(el) {
    if (isPreloaderActive(el)) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = false;
        }
        return;
    }
    currentPreloader = el ? el : initGlobalPreloader();
    doShow(currentPreloader);
}
function doShow(el) {
    if (!el.classList.contains(ClassNames.main)) {
        el.classList.add(ClassNames.main);
        el.classList.add(ClassNames.inline);
    }
    el.removeEventListener('transitionend', disable);
    el.classList.add(ClassNames.enabled);
    preloaderActive = true;
    setTimeout(function () { return el.classList.add(ClassNames.active); }, 20);
}
function initGlobalPreloader() {
    var modal = getTopmostModal();
    if (modal) {
        return modal.firstElementChild;
    }
    if (!globalPreloader) {
        globalPreloader = document.createElement('div');
        globalPreloader.classList.add(ClassNames.main);
        document.body.appendChild(globalPreloader);
    }
    return globalPreloader;
}
function disable() {
    this.classList.remove(ClassNames.enabled);
    this.removeEventListener('transitionend', disable);
}
window['showPreloader'] = showPreloader;
